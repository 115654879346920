import React, { useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Container, NavLink, NavItem } from 'reactstrap';
import { default as MassageList, Massage } from './api/massages';
import { useHistory } from 'react-router-dom';
import './massages/massage.scss';
import './massages/massage-quick-book.scss';

type QuickBookState = {
    //status: string | null;
    show: boolean,
    // data: T | T[] | null;
    // error?: Error | null; // alternative string just to show error message, custom error object to send error code, original message, custom message
    loading: boolean;
    // args?: any;
};

const Massages: React.FC = () => {
    const history = useHistory();

    const [redirectToBooking, setRedirectToBooking] = useState(false);

    const handleBookMassageClickEvent2 = useCallback((event: any, massage: string) => {
        window.scrollTo(0,0);
        history.push('/booking');
    }, [history]);

    const massages = useMemo(() => {
        return MassageList.map((massage: Massage, index: number) => {
            // console.log(massage);
            const imageIndex = Math.floor(Math.random() * (massage.images.length - 1)) + 1;
            const altClass = index % 2 === 0 ? ' -alt' : '';
            let priceInfo = [] as JSX.Element[];
            for(let i =0 ;i<massage.duration.length;i++){
                priceInfo.push(<p className="massages__massage-desc"
                    style={{"padding": "0 0 5px 0", "margin": 0}}
                >{massage.duration[i]} - £{massage.price[i]}</p>);
            }

            return (

                <div key={massage.key} className={"massages__massage " + altClass}>
                    <div className="massages__massage-wrapper">
                        <h5>{massage.name}</h5>
                        <img src={massage.image + massage.images[imageIndex]} alt="" width="100%" height="auto"></img>
                        <p className="massages__massage-desc">{massage.description}</p>
                        
                        <div className="massages__massage-desc" 
                        style={{paddingTop: 0, display: "flex", flexDirection: "column",
                            flex: "1 1 auto", justifyContent: "flex-end"
                        }}>
                            <p className="massages__massage-desc"  
                            style={{"padding": 0, fontWeight: "bold", "marginBottom": "5px"}}>Price</p>
                            {priceInfo}
                        </div>
                        {/* <p className="massages__massage-action">
                            <Button type="button" onClick={e => handleBookMassageClickEvent2(e, massage.key)} block>Book Now - £{massage.price} for {massage.duration}</Button>
                        </p> */}
                    </div>
                </div>
            );
        });
    }, [handleBookMassageClickEvent2]);


    return (
        <>
            {redirectToBooking && <Redirect to="/booking" />}
            <Container className="page-container massages" >
                <h1>Services Provided</h1>
                <div className="massages-container">
                    {massages}
                </div>

            </Container >
        </>
    );
};

export default Massages;