import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const baseUrl = "ClientApp";
const rootElement = document.getElementById('root');

// console.log(baseUrl);
// console.log("*********************");
// console.log(process.env.NODE_ENV);
// console.log("*********************");
// console.log('serviceWorker' in navigator);
// console.log("*********************");
// console.log(process.env.PUBLIC_URL);
// console.log("*********************");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

//registerServiceWorker();
unregister();

