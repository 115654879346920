export type Employee = {
    key: string,
    name: string,
    image: string,
    member: boolean,
    jobtitle: string,
    aboutme: string[],
    orderno: number
};

const employees = [
    {
        orderno: 0,
        key: "any",
        name: "Any",
        image: "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/any.jpg",
        member: false,
        jobtitle: '',
        aboutme: []
    },
    {
        orderno: 1,
        key: "nong",
        name: "Nong",
        image: "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/nong.jpg",
        member: true,
        jobtitle: 'Sabai Sabai Owner & Senior Masseuse',
        aboutme: ['Jayson has had a lustrous career in the hairdressing industry already with a impressive back catalogue of campaigns, advertising and tutorials to his name. A Wella Professionals Global Artist of 15 years and former International Technical Creative Director for Toni & Guy where Jayson spent 24 years.'
            , 'Jayson has won numerous awards. He was named Most Wanted Colour Expert in the Creative Head Awards and a finalist in the British Hairdressing Awards amongst other things. He is a also founding member of the prestigious Wella Professionals UK Colour Club, and ongoing ITVA Mentor.']
    },
    // {
    //     orderno: 2,
    //     key: "yasi",
    //     name: "Yasi",
    //     image: "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/yasi.jpg",
    //     member: true,
    //     jobtitle: 'Senior Masseuse',
    //     aboutme: ['Jayson has had a lustrous career in the hairdressing industry already with a impressive back catalogue of campaigns, advertising and tutorials to his name. A Wella Professionals Global Artist of 15 years and former International Technical Creative Director for Toni & Guy where Jayson spent 24 years.'
    //         , 'Jayson has won numerous awards. He was named Most Wanted Colour Expert in the Creative Head Awards and a finalist in the British Hairdressing Awards amongst other things. He is a also founding member of the prestigious Wella Professionals UK Colour Club, and ongoing ITVA Mentor.']
    // },
    {
        orderno: 3,
        key: "kai",
        name: "Kai",
        image: "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/kai2.jpg",
        member: true,
        jobtitle: 'Senior Masseuse',
        aboutme: ['Jayson has had a lustrous career in the hairdressing industry already with a impressive back catalogue of campaigns, advertising and tutorials to his name. A Wella Professionals Global Artist of 15 years and former International Technical Creative Director for Toni & Guy where Jayson spent 24 years.'
        , 'Jayson has won numerous awards. He was named Most Wanted Colour Expert in the Creative Head Awards and a finalist in the British Hairdressing Awards amongst other things. He is a also founding member of the prestigious Wella Professionals UK Colour Club, and ongoing ITVA Mentor.']
    },
    // {
    //     orderno: 4,
    //     key: "nicky",
    //     name: "Nicky",
    //     image: "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/employee/nicky2.jpg",
    //     member: true,
    //     jobtitle: 'Senior Masseuse',
    //     aboutme: ['Jayson has had a lustrous career in the hairdressing industry already with a impressive back catalogue of campaigns, advertising and tutorials to his name. A Wella Professionals Global Artist of 15 years and former International Technical Creative Director for Toni & Guy where Jayson spent 24 years.'
    //         , 'Jayson has won numerous awards. He was named Most Wanted Colour Expert in the Creative Head Awards and a finalist in the British Hairdressing Awards amongst other things. He is a also founding member of the prestigious Wella Professionals UK Colour Club, and ongoing ITVA Mentor.']
    // }
] as Employee[];

export const getEmployeeByKey = (person : string) => {
    let slotTime = person;
    employees.forEach(element => {
        if(element.key === person){
            slotTime = element.name;
            return;
        }
    });

    return slotTime;
};

export default employees;