export type Massage = {
    key: string,
    name: string,
    description: string,
    image: string,
    duration: string[],
    price: string[],
    selected?: boolean,
    masseurs: string | string[],
    images: string[]
};

const massages = [
    {
        "key": "trad_thai",
        "name": "Traditional Thai Massage",
        "description": "A traditional Thai Massage, the perfect option when you want to refresh your body and relax your muscles & bones. It relieves tension headaches and reduces types of back pain. The massage also relieves muscle pain as well as spasticity & joint stiffness. An excellent choice if you’re feeling overall pain and soreness in your bones.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/traditional/",
        "images": ["trad1.jpg", "trad2.jpg", "trad3.jpg", "trad4.jpg", "trad5.jpg", "trad6.jpg", "trad7.jpg",
        "trad8.jpg", "trad9.jpg", "trad10.jpg", "trad11.jpg", "trad12.jpg", "trad13.jpg", "trad14.jpg",
        "trad15.jpeg", "trad16.jpg", "trad17.jpg"],
        "duration": ["30 mins", "60 mins"],
        "price": ["35.00", "55.00"],
        "masseurs": ["person1", "person2", "person3"]
    },
    {
        "key": "neck_shoulder",
        "name": "Neck & Shoulders Massage",
        "description": "A classic amongst massages. This relaxing neck and shoulders massage will get rid of any tension and the spasms from the muscles. It also improves circulation and opens up a path for better blood flow delivering more oxygen to the brain – reducing tension headaches and migraines. A perfect choice for office workers that experience sore shoulders and a tired neck.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/neckshoulder/",
        "images": ["neckshoulder1.jpg", "neckshoulder2.jpg", "neckshoulder3.jpg", "neckshoulder4.jpg", 
        "neckshoulder5.jpg", "neckshoulder6.jpg", "neckshoulder7.jpg", "neckshoulder8.jpg", 
        "neckshoulder9.jpg", "neckshoulder12.jpg", "neckshoulder13.jpg"],
        "duration": ["30 mins", "60 mins"],
        "price": ["35.00", "55.00"],
        "masseurs": "any"
    },
    {
        "key": "thai_foot",
        "name": "Thai Foot Massage",
        "description": "A casual, relaxing foot massage. This classic massage works wonders for circulation in feet and it causes great stress relief. It also improves sleep, injury prevention and faster recovery time. Surprisingly, it also alleviates headaches and migraines, as well as decreases the feeling of anxiety. The perfect option for people that stand or walk around a lot in their day-to-day lives.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/foot/",
        "images": ["foot1.jpg", "foot2.jpg", "foot3.jpg", "foot4.jpg", "foot5.jpg", "foot6.jpg"],
        "duration": ["30 mins", "60 mins"],
        "price": ["35.00", "55.00"],
        "masseurs": "any"
    },
    {
        "key": "head",
        "name": "Head Massage",
        "description": "A very good pick for people with constant headaches. Incredibly refreshing. The massage helps to increase joint mobility and flexibility, improveblood circulation and lymphatic flow. It also frees knots built up by tension, relaxes tissues and aids with releasing of toxins from your body.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/head/",
        "images": ["head1.jpg", "head2.jpg", "head3.jpg", "head4.jpg", "head5.jpg", "head6.jpg", 
        "head7.jpg", "head8.jpg", "head9.jpg", "head10.jpg", "head11.jpg", "head12.jpg", "head13.jpg"],
        "duration": ["30 mins", "60 mins"],
        "price": ["35.00", "55.00"],
        "masseurs": "any"
    },
    {
        "key": "thai_oil",
        "name": "Thai Oil Massage",
        "description": "This interesting massage will help you relax after a weekfull of hard work. It helps reduce flab and gives the muscle more tone. This massage is capable of improving your mood and even help you in beating depression. Your body contains 30 pressure points on the soles of your feet and palms that are related to various organs that allows your body to achieve full relaxation.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/oil/",
        "images": ["oil1.jpg", "oil2.webp", "oil3.jpg", "oil4.jpg", "oil5.jpg", "oil6.jpg", "oil7.jpg", 
        "oil8.jpg", "oil9.jpg", "oil11.jpg", "oil12.jpg"],
        "duration": ["30 mins", "60 mins"],
        "price": ["35.00", "55.00"],
        "masseurs": "any"
    },
    {
        "key": "gua_sha",
        "name": "Gua Sha & Cupping Massage",
        "description": "The Gua Sha massage is an extraordinary massage due to the range of benefits it can cause. Because it involves rubbing or scraping skin with a massage tool, tiny blood vessels known as capillaries near the surface of your skin can burst. This can result in skin bruising and minor bleeding. Practitioners claim that gua sha can benefit the immune system and reduce inflammation. It may also help with headaches and all kinds of pain. It is great for circulation. Sometimes, Gua Sha is used to treat colds, fevers, or problems with the lungs.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/guasha/",
        "images": ["guasha1.jpg", "guasha3.jpg", "guasha4.jpeg", "guasha5.jpg", "guasha6.jpg", "guasha7.jpeg", "guasha8.jpg", "guasha9.jpg"],
        "duration": ["60 mins", "90 mins"],
        "price": ["70.00", "95.00"],
        "masseurs": "any"
    },
    {
        "key": "pregnancy",
        "name": "Pregnancy Massage",
        "description": "This special massage for mothers eases the mother before birth and nurtures the baby. It allows more oxygen to flow to support the developing baby and speeds up labor. The massage can help relieve some of that discomfort and soothe sore muscles when a mom is stressed. Those hormones also reach the baby and can lead to a baby who’s at increased risk for colic cardiovascular disease, hypertension and is harder to soothe. Pregnancy massage can help counteract those stress hormones, so calm mom equals calm baby.",
        "image": "https://assets-sabai-sabi-thai-therapist.s3.eu-west-2.amazonaws.com/assets/massages/pregnancy/",
        "images": ["pregnancy1.jpg", "pregnancy2.jpg", "pregnancy3.jpg", "pregnancy4.jpg", "pregnancy5.jpg", "pregnancy6.jpg", "pregnancy7.jpg"],
        "duration": ["30 mins", "60 mins"],
        "price": ["35.00", "55.00"],
        "masseurs": "any"
    }
] as Massage[];

export const getMassageByKey = (massage : string) => {
    let slotTime = massage;
    massages.forEach(element => {
        if(element.key === massage){
            slotTime = element.name;
            return;
        }
    });

    return slotTime;
};

export default massages;